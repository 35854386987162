/**
 * es6-shim for IE11
 */
// import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import store from './store';
import { store } from 'store/configureStore';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import MailSupport from 'ui/modals/mailSupport/component';
// import init from './init';
import Login from 'login';
import _ from 'lodash';
import $ from 'jquery';
import { PublicClientApplication, EventType, InteractionType, AuthenticationResult, EventMessage } from '@azure/msal-browser';
import { msalConfig, loginRequest } from 'authConfig';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { initI18n } from './i18n';
import App from './App';

// TODO disable for now Pace.start();
const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.get('user') ?? '';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://f178db69ccc048d0bda8e13a5fcf1ea5@tracing.electrificationtools.abb.com/16',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((message: EventMessage) => {
  const params = new window.URLSearchParams(window.location.search);
  if (message.eventType === EventType.HANDLE_REDIRECT_START) {
    if (params.get('sldId') && params.get('toolUrl')) {
      window.localStorage.setItem('phLink', window.location.href);
    }
  } else if (message.eventType === EventType.HANDLE_REDIRECT_END) {
    if (!params.get('sldId') && !params.get('toolUrl')) {
      const phLink = window.localStorage.getItem('phLink');
      if (phLink && phLink.includes('sldId=')) {
        window.localStorage.removeItem('phLink');
        window.location.replace(phLink);
      }
    }
  }
});

const RootComponent = () => {
  const isProduction = window.document.location.hostname === 'tripcurves.econfigure.abb.com';
  if (isProduction) {
    return (
      <Provider store={store}>
        <Suspense fallback={<Login />}>
          <Sentry.ErrorBoundary
            // eslint-disable-next-line no-return-assign
            onError={() => (window.sentryError = true)}
            fallback={<MailSupport title="DOCWEB_ERROR_SUPPORT_CRASH" />}
          >
            <MsalProvider instance={msalInstance}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                username={email}
                authenticationRequest={{ ...loginRequest, loginHint: email }}
              >
                <App />
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </Sentry.ErrorBoundary>
        </Suspense>
      </Provider>
    );
  }
  if (!isProduction && window.location.hostname !== 'localhost') {
    return (
      <Provider store={store}>
        <Suspense fallback={<Login />}>
          <Sentry.ErrorBoundary showDialog>
            <MsalProvider instance={msalInstance}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                username={email}
                authenticationRequest={{ ...loginRequest, loginHint: email }}
              >
                <App />
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </Sentry.ErrorBoundary>
        </Suspense>
      </Provider>
    );
  }
  return (
    <Provider store={store}>
      {
        <Suspense fallback={<Login />}>
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              username={email}
              authenticationRequest={{ ...loginRequest, loginHint: email }}
            >
              <App />
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </Suspense>
      }
    </Provider>
  );
};

const initApp = () => {
  // TODO: import only needed functions in files, not global use.
  const container = document.getElementById('root') || new HTMLElement();
  const root = createRoot(container);
  root.render(<RootComponent />);
  window._ = _;
  window.$ = $;
};

await msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const acc = msalInstance.getAccount({ username: email });
  if (acc) {
    msalInstance.setActiveAccount(acc);
  }
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      if (account.username !== email) {
        msalInstance.loginRedirect({ ...loginRequest, loginHint: email });
      } else {
        msalInstance.setActiveAccount(account);
      }
    }
  });
  initI18n()
    .then(() => initApp())
    .catch((err) => {
      console.error(err);
      initApp();
    });
});
